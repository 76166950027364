<template>
    <div class="content-container mb-10">
    </div>
    <v-footer app class="text-center">
        <v-row class="d-flex justify-center">
            <v-col cols="auto">
                2024 copyrights. 정보책임보호자 : xxx
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default{};
</script>